<template>
  <section class="section-3">
    <div class="content">
      <div class="title">啦撒产品优势</div>
      <div class="line">
        <div class="line-color" />
      </div>
      <div class="info">
        <div class="item">
          <div class="img">
            <img src="../../../assets/images/home/merit-1.png" alt="" />
          </div>
          <div class="right-text">
            <div class="title">
              <div class="line" />
              <div class="title-text">通讯技术优势</div>
            </div>
            <div class="article">
              <p>自主智能模块及通信技术研发</p>
              <p>通讯技术采用4G+LoRa组合</p>
              <p>解决自建网络及通信死角问题</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-text">
            <div class="title">
              <div class="line" />
              <div class="title-text">工业设计+制造优势</div>
            </div>
            <div class="article">
              <p>具有自主产品设计知识产权</p>
              <p>小米白电产品制造商合作</p>
              <p>执行小米生态链产品生产标准</p>
            </div>
          </div>
          <div class="img">
            <img src="../../../assets/images/home/merit-2.png" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../../../assets/images/home/merit-3.png" alt="" />
          </div>
          <div class="right-text">
            <div class="title">
              <div class="line" />
              <div class="title-text">自主研发优势</div>
            </div>
            <div class="article">
              <p>啦撒出纸机设备、啦撒后台、</p>
              <p>前端应用等皆为自主研发</p>
              <p>核心知识产权近三十项</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="right-text">
            <div class="title">
              <div class="line" />
              <div class="title-text">供应链整合优势</div>
            </div>
            <div class="article">
              <p>整合小米供应链制造商</p>
              <p>标准化采购、审批流程</p>
            </div>
          </div>
          <div class="img">
            <img src="../../../assets/images/home/merit-4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
};
</script>

<style scoped lang="less">
.section-3 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 680px;
    display: flex;
    flex-direction: column;

    > .title {
      margin-left: 305px;
      width: 284px;
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: flex;
      justify-content: center;
    }

    > .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #f2f4f8;
      position: relative;

      .line-color {
        margin-left: 305px;
        width: 284px;
        height: 4px;
        background: #fe690c;
      }
    }
    .info {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 590px;
        height: 270px;
        background: #ffffff;
        box-shadow: 0 5px 12px 0 rgba(185, 185, 185, 0.5);
        display: flex;
        .img {
          width: 50%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right-text {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            width: 190px;
            height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
            display: flex;
            align-items: center;
          }
          .article {
            width: 200px;
            margin: 20px 0 0 5px;
            font-size: 14px;
            color: #555555;
            line-height: 22px;
          }
        }
      }
      .item:nth-child(n + 3) {
        margin-top: 20px;
      }
      .line {
        margin-right: 8px;
        width: 3px;
        height: 13px;
        background: #fe690c;
      }
    }
  }
}
</style>
